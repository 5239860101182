module.exports = {
    siteUrl: `http://trcorner.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Learn, code and share`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `trcorner`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `trcorner`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest

    // Settings
    codeinjection_foot: null,
    codeinjection_head: null,
    codeinjection_styles: ``,
    icon: `favicon.png`,
    cover_image: `https://static.ghost.org/v1.0.0/images/blog-cover.jpg`,
    description: `Learn, code and share`,
    language: `en`,
    logo: `/images/trcorner-logo.svg`,
    navigation: [
        {
            label: `Home`,
            url: `/`,
        },
        {
            label: `About me`,
            url: `/about`,
        },
    ],
    timezone: `Etc/UTC`,
    twitter: `@trcorner9`,
    title: `trcorner`,
    facebook: `vantruong1809`,
    youtube: `UCfNKvz0El8te04g9Bze8vMw`,

    // shareImageWidth: 1000, // Change to the width of your default share image
    // shareImageHeight: 523, // Change to the height of your default share image
}
