import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import Img from 'gatsby-image'
import removeFrontmatterInArray from '/src/utils/trhelper'

const PostCard = ({ post }) => {
    const url = `/${post.frontmatter.slug}/`
    const readingTime = readingTimeHelper(post)
    post = {
        ...post,
        tags: removeFrontmatterInArray(post.frontmatter.tags),
    }

    return (
        <Link to={url} className="post-card">
            <header className="post-card-header">
                {post.frontmatter.feature_image &&
                post.frontmatter.feature_image.childImageSharp && (
                    <Img style={{ marginBottom: `1vw` }}
                        fluid={
                            post.frontmatter.feature_image
                                .childImageSharp.fluid
                        }
                        alt={post.frontmatter.title}
                    />
                )}
                {/* {post.frontmatter.feature_image &&
                    <div className="post-card-image" style={{
                        backgroundImage: `url(${post.frontmatter.feature_image})` ,
                    }}></div>} */}
                {post.frontmatter.tags && <div className="post-card-tags"> <Tags post={post} visibility="public" autolink={false} /></div>}
                {post.frontmatter.featured && <span>Featured</span>}
                <h2 className="post-card-title">{post.frontmatter.title}</h2>
            </header>
            <section className="post-card-excerpt">{post.excerpt}</section>
            <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    <div className="post-card-avatar">
                        {post.frontmatter.author.frontmatter.profile_image ?
                            <img className="author-profile-image" src={post.frontmatter.author.frontmatter.profile_image} alt={post.frontmatter.author.frontmatter.name}/> :
                            <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.frontmatter.author.frontmatter.name}/>
                        }
                    </div>
                    <span>{ post.frontmatter.author.frontmatter.name }</span>
                </div>
                <div className="post-card-footer-right">
                    <div>{readingTime}</div>
                </div>
            </footer>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        id: PropTypes.any,
        frontmatter: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            feature_image: PropTypes.object,
            featured: PropTypes.bool,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                })
            ),
            author: PropTypes.shape({
                frontmatter: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    profile_image: PropTypes.string,
                }),
            }).isRequired,
        }),
        excerpt: PropTypes.string.isRequired,
    }).isRequired,
}

export default PostCard
