import _ from 'lodash'

const removeFrontmatterInArray = function removeFrontmatterInArray(data) {
    let result = []
    _.forEach(data, function (v) {
        result.push(v.frontmatter)
    })

    return result
}

export default removeFrontmatterInArray
